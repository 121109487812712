import { ITracker } from "@easypost/api";
import { Coverage } from "./coverage";
import { Parcel, ShipmentRates } from "./outbound-labels";
import {
  InputAnswer,
  MultipleChoiceAnswer,
  ReturnableItem,
} from "./return-flow";
import { Label, Rate } from "./shipment";
import { OriginAddress } from "./shopify-order";
import { Team } from "./team";
import { TimelineEvent } from "./timeline";
import { ITrackable } from "./trackable";

export type { ITracker, ITrackerStatus, ITrackingDetail } from "@easypost/api";

export interface DiscountCode {
  code: string;
  amount: string;
  type: string;
}

export interface Order extends ITrackable {
  coverage: Coverage;
  protected: boolean;
  redoPrice?: string;
  packageProtected: boolean;
  finalSaleReturnsProtected: boolean;
  credits: number;
  isExchangeOrder: boolean;
  coverageEnabled: boolean;
  _id: string;
  shopify_id: string;
  team: string;
  createdAt: string;
  customer_name: string;
  lineItemsFulfillment: any[];
  lineItems?: any[];
  stalledInFullfillment?: boolean;
  discount?: {
    id: string;
    description: string;
    code: string;
    expirationDateTime: string;
  };
  shopify: {
    id: number;
    line_items: LineItem[];
    fulfillments: Fulfillment[];
    [key: string]: any;
    total_price: string;
    total_tax: string;
    financial_status: string;
    discount_codes: DiscountCode[];
    taxes_included: boolean;
  };
  shopifyCreatedAt: string;
  shopifyUpdatedAt: string;
  timeline: TimelineEvent[];
  updatedAt: string;
  originItems?: OriginItem[];
  currentEmailFlows?: {
    emailFlowId: string;
    currentStep: number;
    continueDate: string;
    fulfillmentId?: string;
  }[];
  trackers: {
    _tracker: ITracker;
    fulfillmentID: string;
  }[];
  trackingAnalytics: {
    email: SesEvent[];
    page: {
      url: string;
      eventType: "ad" | "upsell";
      image?: string;
      createdAt: string;
    }[];
  };
  trackingTextsSent?: {
    sid: string;
    mms: boolean;
    sentAt: string;
  }[];
  trackingEmailsSent?: {
    emailId: string;
    status?: string;
    sentAt: string;
    s3URL?: string;
    trigger?: string;
  }[];
  trackingBillingStatus?: "billed" | "free";
  yofiScores?: {
    return_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [
        {
          name: string;
          is_risk: boolean;
        },
      ];
    };
    bot_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [
        {
          name: string;
          is_risk: boolean;
        },
      ];
    };
    discount_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [
        {
          name: string;
          is_risk: boolean;
        },
      ];
    };
    resell_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [
        {
          name: string;
          is_risk: boolean;
        },
      ];
    };
  };
  customerYofiScores?: {
    return_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [
        {
          name: string;
          is_risk: boolean;
        },
      ];
    };
  };
  shipmentRates?: ShipmentRates;
  parcels?: Parcel[];
  labels?: {
    label: Label;
    rate: Rate;
    printed: boolean;
  }[];
  provider?: Provider;
}

export interface OrderWithReturnableItems extends Order {
  returnableItems: ReturnableItem[];
  line_items?: LineItem[];
}

export enum Provider {
  COMMENTSOLD = "commentsold",
  SHOPIFY = "shopify",
  COMMERCE_CLOUD = "commerce-cloud",
}

export enum YofiRiskLevels {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export interface OrdersFacets {
  total: number;
}

export interface TaxLine {
  price: string;
  rate: number;
  title: string;
}

export interface LineItem {
  id: number;
  product_id: number | null;
  sku: string;
  properties: { name: string; value: string }[];
  title: string;
  variant_title: string;
  variant_id: number;
  [key: string]: any;
  tax_lines: TaxLine[];
  discount_price: string | null;

  greenReturn?: boolean;
  inputAnswers?: InputAnswer[];
  merchantAddress?: any;
  multipleChoiceAnswers?: MultipleChoiceAnswer[];
  reason: string | null;
  selectedReturnType?: string;
  tags?: string[];
}

export interface OriginItem {
  unitPrice: string;
  preDiscountPrice?: string;
  currency: string;
  tax: string;
  originVariantId: number | string;
  newVariantId: number | string;
  applicableShopifyOrder?: number;
}

export interface Tracker {
  tracking_code: string;
  carrier: string;
  status: string;
  est_delivery_date: string;
  public_url?: string;
  created_at: string;
  updated_at: string;
  tracking_details: {
    message: string;
    status: string;
    datetime: string;
  }[];
}

export interface Fulfillment {
  id: number;
  name: string;
  status: string;
  tracking_company: string | null;
  tracking_url: string | null;
  shipment_status: string | null;
  tracking_numbers: string[];
  tracking_urls: string[];
  line_items: LineItem[];
  created_at: string;
  updated_at?: string;
  location_id?: string;
  origin_address?: OriginAddress;
}

export interface ShopifyAddress {
  name?: string;
  first_name?: string;
  last_name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  country?: string;
  country_code?: string;
  zip?: string;
  phone?: string;
  company?: string;
}

export class ExchangeOrderFormat {
  constructor(private readonly settings: Team["settings"]["exchanges"]) {}

  getName(original: string) {
    if (this.settings.orderName.useShopifyGeneratedName) {
      return null;
    }
    if (this.settings.suffix !== null) {
      return this.settings.suffix
        ? `${original}-${this.settings.suffix}`
        : original;
    }
    return (
      this.settings.orderName.prefix + original + this.settings.orderName.suffix
    );
  }

  isName(name: string) {
    if (this.settings.suffix !== null) {
      return (
        !!this.settings.suffix && name.endsWith(`-${this.settings.suffix}`)
      );
    }
    return (
      (this.settings.orderName.prefix || this.settings.orderName.suffix) &&
      name.startsWith(this.settings.orderName.prefix) &&
      name.endsWith(this.settings.orderName.suffix)
    );
  }
}

interface SesBounceEvent {
  eventType: "Bounce";
  bounce: {
    bounceType: string;
    bounceSubType: string;
    bouncedRecipients: {
      emailAddress: string;
      status: string;
      action: string;
      diagnosticCode: string;
    }[];
    timestamp: string;
    feedbackId: string;
  };
}

interface SesComplaintEvent {
  eventType: "Complaint";
  complaint: {
    complainedRecipients: {
      emailAddress: string;
    }[];
    timestamp: string;
    feedbackId: string;
    userAgent: string;
    complaintFeedbackType: string;
  };
}

interface SesDeliveryEvent {
  eventType: "Delivery";
  delivery: {
    timestamp: string;
    recipients: string[];
  };
}

interface SesSendEvent {
  eventType: "Send";
  mail: {
    timestamp: string;
    source: string;
    messageId: string;
    destination: string[];
  };
}

interface SesOpenEvent {
  eventType: "Open";
  open: {
    timestamp: string;
    ipAddress: string;
    userAgent: string;
  };
}

interface SesClickEvent {
  eventType: "Click";
  click: {
    timestamp: string;
    link: string;
    userAgent: string;
    ipAddress: string;
  };
}

interface SesSubscriptionEvent {
  eventType: "Subscription";
  subscription: {
    contactList: string;
    timestamp: string;
    source: string;
    newTopicPreferences: {
      unsubscribeAll: boolean;
      topicSubscriptionStatus: {
        topicName: string;
        subscriptionStatus: string;
      }[];
    };
    oldTopicPreferences: {
      unsubscribeAll: boolean;
      topicSubscriptionStatus: {
        topicName: string;
        subscriptionStatus: string;
      }[];
    };
  };
}

export type SesEvent =
  | SesBounceEvent
  | SesComplaintEvent
  | SesDeliveryEvent
  | SesSendEvent
  | SesOpenEvent
  | SesClickEvent
  | SesSubscriptionEvent;
